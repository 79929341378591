/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Card, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

import Apis from "apis/remotes";

import {
  Form,
  Input,
  Pagination,
  PaginationProps,
  Table,
  TimePicker,
  Typography,
  message,
} from "antd";
import { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";
import MDButton from "components/MDButton";
import { AdminPhoneType, IAdministratorPhoneData } from "apis/response";
import { ColumnsType } from "antd/es/table";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";
import AdminPhoneUploadModal from "./AdminPhoneUploadModal";
import { inputToPhone } from "utils/PhoneString";
const disabledHours = () => {
  const hours = [];
  for (let i = 0; i < 24; i++) {
    if (i < 19 || i > 23) {
      hours.push(i);
    }
  }
  return hours;
};
// const disabledMinutes = (selectedHour: any) => {
//   const minutes = [];
//   if (selectedHour === 23) {
//     for (let i = 1; i < 60; i++) {
//       minutes.push(i);
//     }
//   }
//   return minutes;
// };
function Setting(): JSX.Element {
  const [form] = useForm();

  const [data, setData] = useState<IAdministratorPhoneData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [open, setOpen] = useState(false);

  const putAdministratorSetting = (e: FormData & any) => {
    const { adminSettingId, autoApproveTime } = e;
    console.log(adminSettingId, dayjs(autoApproveTime).format("HH:mm:00"));
    Apis.putAdministratorAdminSettingById(adminSettingId, {
      autoApproveTime: dayjs(autoApproveTime).format("HH:mm:00"),
    })
      .then((resolve) => {
        message.success("success");
      })
      .catch((error) => {
        console.log(error);
        console.log(error);
      });
  };
  useEffect(() => {
    Apis.getAdministratorAdminSetting()
      .then((resolve) => {
        console.log(resolve.data.data[0]);
        form.setFieldsValue({
          adminSettingId: resolve.data.data[0].adminSettingId,
          autoApproveTime: dayjs(resolve.data.data[0].autoApproveTime, "HH:mm:ss"),
        });
      })
      .catch((error) => {
        console.log(`${error.response.data.message ?? ""}`);
      });
  }, []);

  useEffect(() => {
    Apis.getAdministratorAdminPhone({
      page: currentPage,
      perPage: 10,
    })
      .then((resolve) => {
        console.log(resolve);
        setData(resolve.data.data);
        setTotal(resolve.data.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage]);

  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
  };

  const tableColumn: ColumnsType<IAdministratorPhoneData> = [
    /**
     * adminPhoneId : PK
adminPhoneName : 이름 string 필수
adminPhone : 휴대폰번호 string 필수
adminPhoneMemo : 메모 string 필수아님
adminPhoneType : 타입
     */
    {
      title: "이름",
      dataIndex: "adminPhoneName",
      key: "adminPhoneName",
      ellipsis: true,
    },
    {
      title: "휴대폰번호",
      dataIndex: "adminPhone",
      key: "adminPhone",
      ellipsis: true,
      render: (adminPhone: string) => inputToPhone(adminPhone),
    },
    {
      title: "메모",
      dataIndex: "adminPhoneMemo",
      key: "adminPhoneMemo",
      ellipsis: true,
    },
    {
      title: "타입",
      dataIndex: "adminPhoneType",
      key: "adminPhoneType",
      align: "center",
      ellipsis: true,
      render: (adminPhoneType: AdminPhoneType) => {
        switch (adminPhoneType) {
          case "totalDataEveryday":
            return "매일 전송";

          case "totalDataOneDay":
            return "월요일 전송";
          default:
            return "";
        }
      },
    },
    {
      title: "",
      dataIndex: "adminPhoneId",
      key: "adminPhoneId",
      ellipsis: true,
      render: (adminPhoneId: number) => (
        <MDButton size="small" color="error" onClick={() => handleDeleteAdminPhone(adminPhoneId)}>
          삭제
        </MDButton>
      ),
    },
  ];
  const handleDeleteAdminPhone = (adminPhoneId: number) => {
    Apis.deleteAdministratorAdminPhoneById(adminPhoneId)
      .then((resolve) => {
        Apis.getAdministratorAdminPhone({
          page: currentPage,
          perPage: 10,
        })
          .then((resolve) => {
            console.log(resolve);
            setData(resolve.data.data);
            setTotal(resolve.data.total);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOpenState = () => {
    setOpen((prev) => !prev);
  };

  const handlePostAdminPhone = (data: any) => {
    console.log(data);
    Apis.postAdministratorAdminPhone(data)
      .then((resovle) => {
        Apis.getAdministratorAdminPhone({
          page: currentPage,
          perPage: 10,
        })
          .then((resolve) => {
            console.log(resolve);
            setData(resolve.data.data);
            setTotal(resolve.data.total);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox p={2}>
            <Form form={form} onFinish={putAdministratorSetting}>
              <FormItem hidden label="adminSettingId" name={"adminSettingId"}>
                <Input type="text" />
              </FormItem>

              <MDBox display="flex" alignItems="center" justifyContent="space-between" m={2}>
                <FormItem
                  name={"autoApproveTime"}
                  extra="자동 승인 설정 시간은 19:00부터 23:59까지만 적용가능합니다."
                  label="자동 승인 시간"
                  rules={[{ required: true, message: "자동 승인 시간을 설정하세요." }]}
                  style={{ marginRight: "16px" }} // 간격을 위한 스타일
                >
                  <TimePicker
                    format={"HH:mm"}
                    disabledHours={disabledHours}
                    // disabledMinutes={disabledMinutes}
                  />
                </FormItem>

                {/* 버튼을 같은 행에 배치 */}
                <FormItem>
                  <MDButton type="submit" color="info" variant="gradient">
                    변경
                  </MDButton>
                </FormItem>
              </MDBox>
            </Form>

            <Divider />

            <Table
              title={() => (
                <MDBox justifyContent="space-between" display="flex">
                  <MDTypography components="h3">종합데이터 전송 관리</MDTypography>
                  <MDButton color="info" variant="gradient" onClick={handleOpenState}>
                    추가
                  </MDButton>
                </MDBox>
              )}
              pagination={false}
              dataSource={data}
              columns={tableColumn}
              footer={() => (
                <Pagination
                  current={currentPage}
                  onChange={handleOnChangePage}
                  pageSize={10}
                  total={total}
                  showSizeChanger={false}
                />
              )}
            />
          </MDBox>
        </Card>
      </MDBox>
      {open ? (
        <AdminPhoneUploadModal
          open={open}
          handleOpenState={handleOpenState}
          handlePostAdminPhone={handlePostAdminPhone}
        />
      ) : null}
    </DashboardLayout>
  );
}
export default Setting;
